import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import auth from '@/common/auth'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    meta: { requiresLogin: true },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard.vue'),
      },
      {
        path: '/content',
        name: 'Content',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/',
        children: [
          {
            path: '/content/renungan-harian',
            name: 'RenunganHarian',
            component: () => import('@/views/Devotional.vue'),
          },
          {
            path: '/content/ayat-mingguan',
            name: 'Ayat Mingguan',
            component: () => import('@/views/WeeklyVerse.vue'),
          },
          {
            path: '/content/warta-jemaat',
            name: 'Warta Jemaat',
            component: () => import('@/views/WartaJemaat.vue'),
          },
          {
            path: '/content/widgets',
            name: 'Widgets',
            component: () => import('@/views/Widgets.vue'),
          },
        ],
      },
      {
        path: '/absensi',
        name: 'Absensi',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/',
        children: [
          {
            path: '/absensi/event',
            name: 'AbsensiEvent',
            component: () => import('@/views/AbsenceEvent.vue'),
          },
          {
            path: '/absensi/event/:eventId',
            name: 'AbsensiEventDetail',
            component: () => import('@/views/AbsenceEventDetail.vue'),
          },
        ],
      },
      {
        path: '/masterdata',
        name: 'Masterdata',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/',
        children: [
          {
            path: '/masterdata/group',
            name: 'Masterdata Group',
            component: () => import('@/views/Groups.vue'),
          },
          {
            path: '/masterdata/komisi',
            name: 'Masterdata Komisi',
            component: () => import('@/views/Comission.vue'),
          },
          {
            path: '/masterdata/diaken',
            name: 'Masterdata Diaken',
            component: () => import('@/views/DiakenMemberConnect.vue'),
          },
        ],
      },
      {
        path: '/ministry',
        name: 'Ministry',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/',
        children: [
          {
            path: '/ministry/attributes',
            name: 'Ministry Attributes',
            component: () => import('@/views/MinistryAttribute.vue'),
          },
          {
            path: '/ministry/schedule',
            name: 'Ministry Schedule',
            component: () => import('@/views/MinistrySchedule.vue'),
          },
          {
            path: '/ministry/schedule/:comissionId/create',
            name: 'Ministry Schedule Create',
            component: () => import('@/views/MinistryScheduleCreate.vue'),
          },
          {
            path: '/ministry/schedule/:comissionId/create/:scheduleId',
            name: 'Ministry Schedule Edit',
            component: () => import('@/views/MinistryScheduleCreate.vue'),
          },
        ],
      },
      {
        path: '/room-booking',
        name: 'Room Booking',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/',
        children: [
          {
            path: '/room-booking/create',
            name: 'Room Booking Create',
            component: () => import('@/views/RoomBookingForm.vue'),
          },
          {
            path: '/room-booking/attributes',
            name: 'Room Booking Attributes',
            component: () => import('@/views/RoomBookingRooms.vue'),
          },
          {
            path: '/room-booking/pre-booked',
            name: 'Prebooking Schedule',
            component: () => import('@/views/RoomPrebooking.vue'),
          },
          {
            path: '/room-booking/schedule',
            name: 'Schedule',
            component: () => import('@/views/RoomBookingSchedule.vue'),
          },
        ],
      },
      {
        path: '/program',
        name: 'Program GKII',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/program/dashboard',
        children: [
          {
            path: '/program/laporan/raker',
            name: 'Laporan Pengeluaran Raker',
            component: () =>
              import('@/views/program/LaporanPengeluaranBudget.vue'),
          },
          {
            path: '/program/laporan/non-raker',
            name: 'Laporan Pengeluaran Non-Raker',
            component: () =>
              import('@/views/program/LaporanPengeluaranBudgetNonRaker.vue'),
          },
          {
            path: '/program/dashboard',
            name: 'Dashboard Program',
            component: () => import('@/views/program/ProgramDashboard.vue'),
          },
          {
            path: '/program',
            name: 'All Program Raker',
            component: () => import('@/views/program/ListProgramRaker.vue'),
          },
          {
            path: '/program/add',
            name: 'Add Program',
            component: () => import('@/views/program/AddProgram.vue'),
          },
          {
            path: '/program/non-raker',
            name: 'All Program Non-Raker',
            component: () => import('@/views/program/ListProgramNonRaker.vue'),
          },
          {
            path: '/program/non-raker/add',
            name: 'Add Program Non Raker',
            component: () =>
              import('@/views/program/RequestBudgetNonRaker.vue'),
          },
          {
            path: '/program/non-raker/settings',
            name: 'Setting Non Raker',
            component: () => import('@/views/program/SettingNonRaker.vue'),
          },
          {
            path: '/program/non-raker/detail/:requestId?',
            name: 'Detail Program Non Raker',
            component: () => import('@/views/program/DetailNonRaker.vue'),
          },
          {
            path: '/program/non-raker/detail/:requestId?/budget',
            name: 'Detail Budget Program Non Raker',
            component: () => import('@/views/program/BudgetNonRakerDetail.vue'),
          },
          {
            path: '/program/non-raker/detail/:requestId?/laporan/:laporanId?',
            name: 'Laporan Keuangan Program Non Raker',
            component: () =>
              import('@/views/program/LaporanKeuanganNonRaker.vue'),
          },
          {
            path: '/program/:programId',
            name: 'Detail Program',
            component: () => import('@/views/program/DetailProgram.vue'),
          },
          {
            path: '/program/:programId/budget',
            name: 'Request Budget Program',
            component: () => import('@/views/program/RequestBudgetProgram.vue'),
          },
          {
            path: '/program/:programId/laporan/keuangan/:laporanId?',
            name: 'Laporan Keuangan',
            component: () => import('@/views/program/LaporanKeuangan.vue'),
          },
          {
            path: '/program/:programId/laporan/program/:laporanId?',
            name: 'Laporan Program',
            component: () => import('@/views/program/LaporanProgram.vue'),
          },
          {
            path: '/program/:programId/budget/:budgetId',
            name: 'Detail Budget',
            component: () => import('@/views/program/BudgetDetail.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/forgot-password',
    name: 'Forget Password',
    component: () => import('@/views/ForgetPassword.vue'),
  },
  { path: '/:pathMatch(.*)*', redirect: '/' },
  { path: '/:pathMatch(.*)', redirect: '/' },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresLogin)) {
    if (
      navigator.userAgent.includes('GKIIOne-Mobile ') &&
      !auth.isUserLoggedIn()
    ) {
      var token = navigator.userAgent.split(' ')
      if (token.length === 2) {
        auth.silentLogin(this, token[1], () => {
          auth.isUserAuthorized(to, (success) => {
            if (success) {
              next()
            } else {
              router.replace({
                name: 'Login',
                query: {
                  nextUrl: to.fullPath,
                },
              })
            }
          })
        })
      } else {
        router.replace({
          name: 'Login',
          query: {
            nextUrl: to.fullPath,
          },
        })
      }
    } else {
      auth.isUserAuthorized(to, (success) => {
        if (success) {
          next()
        } else {
          router.replace({
            name: 'Login',
            query: {
              nextUrl: to.fullPath,
            },
          })
        }
      })
    }
  } else {
    next()
  }
})

export default router
