export default [
  {
    component: 'CNavTitle',
    name: 'Content',
    level: ['GKII_SEKRETARIAT_ADMIN', 'GKII_CONTENT'],
  },
  // {
  //   component: 'CNavItem',
  //   name: 'Banners',
  //   to: '/content/banners',
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'News',
  //   to: '/content/news',
  // },
  {
    component: 'CNavItem',
    name: 'Ayat Mingguan',
    to: '/content/ayat-mingguan',
    level: ['GKII_SEKRETARIAT_ADMIN', 'GKII_CONTENT'],
  },
  {
    component: 'CNavItem',
    name: 'Warta Jemaat',
    to: '/content/warta-jemaat',
    level: ['GKII_SEKRETARIAT_ADMIN', 'GKII_CONTENT'],
  },
  // {
  //   component: 'CNavItem',
  //   name: 'GKII-TV',
  //   to: '/content/gkii-tv',
  // },
  {
    component: 'CNavItem',
    name: 'Renungan Harian',
    to: '/content/renungan-harian',
    level: ['GKII_SEKRETARIAT_ADMIN', 'GKII_CONTENT'],
  },
  {
    component: 'CNavItem',
    name: 'Widgets',
    to: '/content/widgets',
    level: ['GKII_SEKRETARIAT_ADMIN', 'GKII_CONTENT'],
  },
  {
    component: 'CNavTitle',
    name: 'Kegiatan Gereja',
    level: [],
  },
  {
    component: 'CNavGroup',
    name: 'Program Kerja',
    to: '/program',
    level: [],
    items: [
      {
        component: 'CNavItem',
        name: 'Ringkasan Informasi',
        to: '/program/dashboard',
      },
      {
        component: 'CNavItem',
        name: 'Program Raker',
        to: '/program/',
        level: [],
      },
      {
        component: 'CNavItem',
        name: 'Program Non-Raker',
        to: '/program/non-raker',
        level: [],
      },
      {
        component: 'CNavItem',
        name: 'Settings Budget Non-Raker',
        to: '/program/non-raker/settings',
        level: ['GKII_SEKRETARIAT_ADMIN'],
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Pelayanan',
    to: '/ministry',
    level: ['GKII_SEKRETARIAT_ADMIN', 'GKII_PENGURUS'],
    items: [
      {
        component: 'CNavItem',
        name: 'Atribut Pelayan',
        to: '/ministry/attributes',
        level: ['GKII_SEKRETARIAT_ADMIN'],
      },
      {
        component: 'CNavItem',
        name: 'Jadwal Pelayanan',
        to: '/ministry/schedule',
        level: ['GKII_PENGURUS'],
      },
    ],
  },
  {
    component: 'CNavTitle',
    name: 'Fasilitas Gereja',
    level: ['GKII_SEKRETARIAT_ADMIN'],
  },

  {
    component: 'CNavGroup',
    name: 'Peminjaman Ruangan',
    to: '/room-booking',
    level: ['GKII_SEKRETARIAT_ADMIN'],
    items: [
      {
        component: 'CNavItem',
        name: 'Form Booking',
        to: '/room-booking/create',
      },
      {
        component: 'CNavItem',
        name: 'Daftar Ruangan',
        to: '/room-booking/attributes',
      },
      {
        component: 'CNavItem',
        name: 'Jadwal Prebook',
        to: '/room-booking/pre-booked',
      },
      {
        component: 'CNavItem',
        name: 'Jadwal Peminjaman',
        to: '/room-booking/schedule',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Absensi',
    to: '/absensi',
    level: ['GKII_SEKRETARIAT_ADMIN', 'GKII_PENGURUS_ABSEN'],
    items: [
      {
        component: 'CNavItem',
        name: 'Daftar Event',
        to: '/absensi/event',
      },
    ],
  },
  {
    component: 'CNavTitle',
    name: 'User Management',
    level: ['GKII_SEKRETARIAT_ADMIN'],
  },
  {
    component: 'CNavGroup',
    name: 'Masterdata',
    to: '/masterdata',
    level: ['GKII_SEKRETARIAT_ADMIN'],
    items: [
      {
        component: 'CNavItem',
        name: 'User',
        to: '/masterdata/user',
      },
      {
        component: 'CNavItem',
        name: 'Komisi',
        to: '/masterdata/komisi',
      },
      {
        component: 'CNavItem',
        name: 'Group',
        to: '/masterdata/group',
      },
      {
        component: 'CNavItem',
        name: 'Diaken',
        to: '/masterdata/diaken',
      },
    ],
  },
]
